import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-home-solex',
  templateUrl: './home-solex.component.html',
  styleUrls: ['./home-solex.component.scss']
})
export class HomeSolexComponent implements OnInit {

  AvailableBundles = [
    {
      Description: "Builder Wired Headset Bundle with ao scan no graphics",
      imageUrl: "assets/images/bundle-images/Builder Wired Headset Bundle with ao scan no graphics.jpg",
      itemID: 778
    },
    {
      Description: "Builder Bluetooth Bundle with ao scan no graphic",
      imageUrl: "assets/images/bundle-images/Builder Bluetooth Bundle with ao scan_no graphic.jpg",
      itemID: 780
    },
    {
      Description: "Starter Bluetooth Bundle with ao scan no graphic",
      imageUrl: "assets/images/bundle-images/Starter Bluetooth Bundle with ao scan no graphic.jpg",
      itemID: 779
    },
    {
      Description: "Starter Wired Headset Bundle with ao scan no graphics",
      imageUrl: "assets/images/bundle-images/Starter Wired Headset Bundle with ao scan no graphics.jpg",
      itemID: 777
    },
    {
      Description: "Basic Bluetooth Bundle",
      imageUrl: "assets/images/bundle-images/Basic Bluetooth Bundle.jpg",
      itemID: 129
    },
  ];
  public BundlesConfig: SwiperConfigInterface = {};

  constructor() { }

  ngOnInit(): void {
    this.BundlesConfig = {
      observer: true,
      direction: "horizontal",
      slidesPerView: 3,
      spaceBetween: 80,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    };
  }

}
