import { Pipe, PipeTransform } from "@angular/core";
const SolexMembershipItemCode: number = 39;

@Pipe({
  name: "productSearch",
  pure: false,
})
export class ProductSearchPipe implements PipeTransform {
  
  transform(items, filter: string, filterOptions: any) {

    function removeMembershipItems(items) {
      return items.filter(item => {
        // return (item.ItemID != 97 && item.ItemID != 39);
        return (item.ItemID != SolexMembershipItemCode);
      });
    }

    function removeInstallmentsItems(items) {
      return items.filter(item => {
        return (!item.SKU.includes("-i") && !item.SKU.includes("-ins"));
      });
    }

    if (!items || (!filter && (filterOptions && !filterOptions["best-seller"] && !filterOptions["new"]))) {
      items = removeMembershipItems(items);
      items = removeInstallmentsItems(items);
      return items;
    }

    let filteredItems = [];
    const searchedItems = [];
    const isBestsellerChecked = filterOptions && filterOptions["best-seller"];
    const isNewChecked = filterOptions && filterOptions["new"];

    const removeDuplicates = (arr) => {
      const uniqueItems = {};
      const result = [];
    
      arr.forEach(item => {
        if (!uniqueItems[item.ItemID]) {
          uniqueItems[item.ItemID] = true;
          result.push(item);
        }
      });
    
      return result;
    };

    items.map(i => {

      // Searching By Name
      if((i.ProductName?.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      i.Description?.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      i.SKU?.toLowerCase().indexOf(filter.toLowerCase()) > -1)) {
        searchedItems.push(i);
      }

      filteredItems = searchedItems;

      if (isBestsellerChecked && isNewChecked) {
        filteredItems = searchedItems.filter(product => product.Custom?.Field3 || product.Custom?.Field4);
      } else if (isBestsellerChecked) {
        filteredItems = searchedItems.filter(product => product.Custom?.Field3);
      } else if (isNewChecked) {
        filteredItems = searchedItems.filter(product => product.Custom?.Field4);
      }
      
    });
    
    filteredItems = removeDuplicates(filteredItems);
    filteredItems = removeMembershipItems(filteredItems);
    filteredItems = removeInstallmentsItems(filteredItems);
    
    return filteredItems;
    
  }
}
