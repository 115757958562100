import {
  Component,
  Input,
  AfterViewInit,
} from "@angular/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/components/shared/services/cart.service";
import { ProductService } from "src/app/components/shared/services/product.service";
import { ConfigService } from "src/app/components/shared/services/config.service";
import { ProductDialogComponent } from "src/app/components/shop/products/product-dialog/product-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import $ from "jquery";
import { ConfirmDialogComponent } from "../model/confirm-dialog/confirm-dialog.component";
import { UserService } from "../services/user.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { Cart1Service } from "../services/cart1.service";
import { OrderService } from "../services/order.service";
@Component({
  selector: "app-customer-type-carousel",
  templateUrl: "./customer-type-carousel.component.html",
  styleUrls: ["./customer-type-carousel.component.scss"],
})
export class CustomerTypeCarouselComponent implements AfterViewInit {
  @Input("customerTypes") customerTypes = [];
  @Input("typeSelected") typeSelected;
  userService: UserServiceModal;
  public config: SwiperConfigInterface = {};
  scrollPosition;
  scrollDuration;
  paddleMargin;
  countrycode : string;
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private router: Router,
    private cartService: CartService,
    private cart1Service: Cart1Service,
    private productService: ProductService,
    public translate: TranslateService,
    public user: UserService,
    public activatedRoute: ActivatedRoute,
    private itemsService: ProductService,
    private orderService: OrderService
  ) {
    this.userService = user.userServiceModal;
  }
  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    };
    this.countrycode = sessionStorage.getItem('selectedCountry')
    if (this.countrycode == 'nz') {
      const url = new URL(window.location.href);
      console.log(url.searchParams.get("type"),"hdfkshdfhsdfsd")
      this.customerTypes = this.customerTypes.filter(x=>{
        return x.ID !=1;
      })
       if (url.searchParams.get("type") == '1' ) {
         this.selectType(3,true);
       }
       else{
         this.selectType(url.searchParams.get("type"),true);
       }
     
    }
    console.log("this.countrycode",this.countrycode)
  

  }

  public openProductDialog(product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((products) => {
      if (products) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }

  // Add to cart
  public addToCart(product, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }
  haveScroll() {
    const menuInvisibleSize =
      this.getMenuSize() - $(".user-types").outerWidth() - this.paddleMargin;
    if (menuInvisibleSize <= 0) {
      return false;
    }
    return true;
  }
  setScrollPosition() {
    if (
      $("#status-user-type-" + this.typeSelected) &&
      $("#status-user-type-" + this.typeSelected).position()
    ) {
      this.scrollPosition = $(
        "#status-user-type-" + this.typeSelected,
      ).position().left;
      $(".user-types").animate(
        { scrollLeft: this.scrollPosition },
        this.scrollDuration,
      );
    }
  }
  slideSectionRight() {
    this.scrollPosition += 250;
    const scrollSize =
      $(".user-type").length * $(".user-type").outerWidth(true);
    if (this.scrollPosition > scrollSize) {
      this.scrollPosition = scrollSize;
    }
    $(".user-types").animate(
      { scrollLeft: this.scrollPosition },
      this.scrollDuration,
    );
  }
  getMenuSize() {
    return $(".user-type").length * $(".user-type").outerWidth(true) - 10;
  }

  selectType(type,iscountryselected :boolean = false) {
    if (iscountryselected) {
      sessionStorage.removeItem('items');
        this.typeSelected = type;
        sessionStorage.setItem("customerSelected", "true");
        if (this.typeSelected != "0") {
          sessionStorage.setItem("selectedCustomerTypeID", this.typeSelected);
          this.userService.customerTypeID = this.typeSelected;
        } else {
          this.typeSelected = 2;
        }
        let currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('type', this.typeSelected); 
        window.history.replaceState(null, '', currentUrl.toString());
       
      return;
    }
   if (type == this.typeSelected) {
      sessionStorage.setItem("customerSelected", "true");
      return;
    }

    const dialogRef1 = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translate.instant("update_customer_title"),
        message: this.translate.instant("update_customer_text"),
        takeaction: this.translate.instant("YES"),
        noaction: this.translate.instant("NO"),
      },
      disableClose: true,
      panelClass: "",
      autoFocus: false,
    });
    dialogRef1.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        sessionStorage.removeItem('items');
        this.typeSelected = type;
        sessionStorage.setItem("customerSelected", "true");
        if (this.typeSelected != "0") {
          sessionStorage.setItem("selectedCustomerTypeID", this.typeSelected);
          this.userService.customerTypeID = this.typeSelected;
        } else {
          this.typeSelected = 2;
        }
        this.router
        .navigate(["/join"], {
          relativeTo: this.activatedRoute,
          queryParams: { type: this.typeSelected },
          queryParamsHandling: "merge",
        })
        .then(() => {
          this.preventCartItemsOnSelectionChange(this.typeSelected);
        });
        // this.cart1Service.clearCart();
      }
    });
  }
  collapseSection(type) {
    if (type == this.typeSelected) {
      this.userService.sponsorSectionPanel = false;
    }
  }

  preventCartItemsOnSelectionChange(selectedCustomerType) {

    //  Remove all Subscription Items if RETAIL type!

    if(selectedCustomerType == 2) {
      this.itemsService.selectedAutoOrderItems.length = 0;
      localStorage.setItem("cart.autoship", JSON.stringify(this.itemsService.selectedAutoOrderItems));
      this.orderService.calculateAutoOrder();
    }

    // Remove AO from cart if RETAIL type!

    if(selectedCustomerType == 2) {
      const AO = this.itemsService.selectedOrderItems.find((item: any) => item.ItemID == 97);
      if(AO) {
        this.removeCartItems("order", AO);
      }
    }

    const CartItems = {
      "order" : this.itemsService.selectedOrderItems || [],
      "pack" : this.itemsService.selectedPacks || [],
      "autoship" : this.itemsService.selectedAutoOrderItems || [],
    };

    const retailRequest = {
      CategoryId: "all" ,
      PriceGroup: selectedCustomerType,
      StoreID: 3,
    };
    
    this.itemsService.getProductByCategory(retailRequest)
    .subscribe((RetailStoreItems: any) => {

      const enrollRequest = {
        CategoryId: "all" ,
        PriceGroup: selectedCustomerType,
        StoreID: 4,
      };

      this.itemsService.getProductByCategory(enrollRequest)
      .subscribe((EnrollStoreItems: any) => { 

        const AllItems = [...RetailStoreItems, ...EnrollStoreItems] || [];

        // Filter Items present in cart, not in API response as per new customer type

        const orderItemsNotExist = CartItems.order.filter((item:any) => !AllItems.some(obj => obj.ItemID == item.ItemID));
        const autoshipItemsNotExist = CartItems.autoship.filter((item:any) => !AllItems.some(obj => obj.ItemID == item.ItemID));
        const packItemsNotExist = CartItems.pack.filter((item:any) => !AllItems.some(obj => obj.ItemID == item.ItemID));

        // Items Removal from Cart
  
        if(orderItemsNotExist.length > 0) {
          for (let i = 0; i < orderItemsNotExist.length; i++) {
            const item = orderItemsNotExist[i];
            this.removeCartItems("order", item);
          }
        }

        if(autoshipItemsNotExist.length > 0) {
          for (let i = 0; i < autoshipItemsNotExist.length; i++) {
            const item = autoshipItemsNotExist[i];
            this.removeCartItems("autoship", item);
          }
        }

        if(packItemsNotExist.length > 0) {
          for (let i = 0; i < packItemsNotExist.length; i++) {
            const item = packItemsNotExist[i];
            this.removeCartItems("pack", item);
          }
        }

        setTimeout(() => {
          window.location.reload();
        }, 1500);

      });

    }, () => {
      this.cart1Service.clearCart();
      window.location.reload();
    });
  }

  removeCartItems(type, item) {
    this.cart1Service.removeFromCart(item, type == "autoship", type == "pack", false);
  }
}
