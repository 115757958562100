import { Component, OnInit, ViewChild } from "@angular/core";
import { CartItem } from "../../modals/cart-item";
import { Router, ActivatedRoute } from "@angular/router";
import { SidebarMenuService } from "../shared/sidebar/sidebar-menu.service";
import { SidenavMenu } from "../shared/sidebar/sidebar-menu.model";
import { UserService } from "../shared/services/user.service";
import { FindEnrollerComponent } from "../shared/model/findenroller/findenroller.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfigService } from "../shared/services/config.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../shared/model/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { RestApiService } from "../shared/services/restapi.service";
import { MatSidenav } from "@angular/material/sidenav";
import { PaymentService } from "../shared/services/payment.service";
import { Cart1Service } from "../shared/services/cart1.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UtilityService } from "../shared/services/utility.service";
import { ProductService } from "../shared/services/product.service";
import { ItemsListService } from "../shared/services/itemsList.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { AccountService } from "../shared/services/account.service";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public sidenavMenuItems;
  public customerTypes;
  public allowedCountries = [];
  public country;
  public userType;
  userServiceModal: UserServiceModal;
  products: Array<DSProduct>;
  welcmtext: string = "";
  selectedLanguageCode;
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  @ViewChild("langMenuTrigger") langMenuTrigger: MatMenuTrigger;
  showTopHeader = false;

  public banners = [];

  public categoriesList;
  public categoriesItemList;
  public url: string;
  navItems: SidenavMenu[] = [
    {
      displayName: "Home",
      iconName: "home",
      route: "/home",
    },
    {
      displayName: "Shop",
      iconName: "feedback",
      route: "/products/all",
    },
    {
      displayName: "Join",
      iconName: "settings",
      route: "/join",
    },
    {
      displayName: "About",
      iconName: "feedback",
      route: "/about",
    },
    // {
    //   displayName: "Contact",
    //   iconName: "contacts",
    //   route: "/contact",
    // },
    {
      displayName: "Sign Out",
      iconName: "feedback",
      route: "/login",
    },
    {
      displayName: "Sign In",
      iconName: "settings",
      route: "/login",
    },
    {
      displayName: "Useful Links",
      iconName: "pages",
      children: [
        {
          displayName: "Refund Policy",
          iconName: "question_answer",
          route: "",
          url: "#"
        },
        {
          displayName: "Shipping and Return Policy",
          iconName: "question_answer",
          route: "",
          url: "assets/pdfs/Return & Shipping Policies.pdf"
        },
        {
          displayName: "Terms and Conditions",
          iconName: "question_answer",
          route: "",
          url: "assets/pdfs/Terms & Conditions.pdf"
        },
        {
          displayName: "Policies and Procedures",
          iconName: "question_answer",
          route: "",
          url: "assets/pdfs/Policies & Procedures.pdf"
        },
      ],
    },
  ];
  @ViewChild("start") public sideBarMenu: MatSidenav;
  constructor(
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public configService: ConfigService,
    private dialog: MatDialog,
    public userService: UserService,
    public router: Router,
    private cart1Service: Cart1Service,
    public sidenavMenuService: SidebarMenuService,
    public apiService: RestApiService,
    public paymentService: PaymentService,
    public utilityService: UtilityService,
    public productService: ProductService,
    public itemsListService: ItemsListService,
    public accountService: AccountService,
  ) {
    this.userServiceModal = this.userService.userServiceModal;
    this.allowedCountries = JSON.parse(
      sessionStorage.getItem("allowedCountries"),
    );
    this.configService.OpenLanguageSelector
    .subscribe((event) => {
      event && this.langMenuTrigger && this.langMenuTrigger.openMenu();
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.welcmtext = this.translate.instant("welcome_");
    }, 3000);

    const selectedCustomerTypeID =
      sessionStorage.getItem("selectedCustomerTypeID") === "undefined"
        ? null : sessionStorage.getItem("selectedCustomerTypeID");

    const typeOfId = _.find(this.configService.commonSettings?.CustomerTypes, (customerType) => {
        return (
          customerType.ID === (parseInt(this.activatedRoute.snapshot.queryParams.type, 10) || parseInt(selectedCustomerTypeID, 10) || this.accountService.isLoggedIn() ? this.userServiceModal.customerTypeID : this.userService.DefaultCustomerType)
        );
      },
    );

    this.userServiceModal.customerTypeID = typeOfId?.ID;
  }

  sildeMobileNav() {
    this.sideBarMenu.toggle();
  }

  public updatecountry(country, languagecode) {
    this.cart1Service.updateCountry(country, languagecode, true, false);
  }

  navigateToChangeAffiliate() {
    this.dialog.open(FindEnrollerComponent, {
      disableClose: true,
      panelClass: "findenroller-dialog",
      autoFocus: false,
    });
  }

  changeCustomerType(ID) {
    if (ID === this.userServiceModal.customerTypeID) {
      return;
    }

    const dialogData = new ConfirmDialogModel(
      this.translate.instant("update_customer_title"),
      this.translate.instant("update_customer_text"),
      this.translate.instant("NO"),
      this.translate.instant("YES"),
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        sessionStorage.removeItem('items');
        this.userServiceModal.customerTypeID = ID;
        sessionStorage.setItem("selectedCustomerTypeID", ID);
        this.router
          .navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { type: ID },
            queryParamsHandling: "merge",
          })
          .then(() => {
            window.location.reload();
          });
        this.cart1Service.clearCart();
      }
    });
  }

  close(item) {
    if (!(item.children && item.children.length)) {
      this.sideBarMenu.close();
    }
  }
  closed(val) {
    if (val != "1") {
      this.sideBarMenu.close();
    }
  }

  customerTypeDescription() {
    if (!this.configService.commonSettings.CustomerTypes) {
      return "";
    }
    if (!this.userServiceModal.customerTypeID) {
      return "";
    }
    return _.find(
      this.configService.commonSettings.CustomerTypes,
      (customerType) => {
        return this.userServiceModal.customerTypeID == customerType.ID;
      },
    ).Description;
  }

  isLoggedIn() {
    if (
      Object.keys(this.userServiceModal.customerData).length &&
      this.userServiceModal.customerData.CustomerId &&
      this.userService.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }
  setNavItemCategories() {
    for (const element of this.categoriesList) {
      if (
        this.configService.localSettings.Global.CategoriesToFetch?.length > 0
      ) {
        if (
          this.configService.localSettings.Global.CategoriesToFetch.indexOf(
            element.Category,
          ) > -1
        ) {
          this.navItems[1].children.push({
            displayName: element.Category,
            iconName: "contacts",
            route: `/products/${element.CategoryId}`,
          });
        }
      } else {
        this.navItems[1].children.push({
          displayName: element.Category,
          iconName: "contacts",
          route: `/products/${element.CategoryId}`,
        });
      }
    }
  }

  handleTopHeader(actioN?: string) {
    if( actioN == "show") {
      console.log("show");
      this.showTopHeader = true;
    }
    if( actioN == "hide") {
      console.log("hide");
      this.showTopHeader = false;
    }
  }
}
