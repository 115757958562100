// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const stageURL = "https://solexapi-stage.ziplingo.com/";
const LiveURL = "https://retailapi.solexnation.com/";

export const environment = {
  production: false,
  apiUrl: (location.host.match(/stage/) || location.hostname === "localhost" || location.hostname.includes('solex1.ziplingo.com')) ? stageURL : LiveURL,
  productUrl: (location.host.match(/stage/) || location.hostname === "localhost" || location.hostname.includes('solex1.ziplingo.com')) ? stageURL : LiveURL,
  imageUrl: "https://solex.corpadmin.directscale.com/CMS/Images/Inventory",
  linkTracking: {
    isAllowed: false,
    companyName: "companyKey",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
